<template>
  <div class="video">
    <div class="video__w">
      <PageHeader :product="product" :prepend="'@Video from'" />
      <div
        v-for="(video, index) in product.getVideos()"
        :key="index"
        class="video__player-w"
      >
        <iframe class="video__player" :src="video.url" />
      </div>
    </div>
    <AsideCard :product="product" :navigation-height="navigationHeight" />
  </div>
</template>

<script setup>
import { Product } from "~/models/product.model";
import PageHeader from "~/modules/product/ui/components/PageHeader.vue";
import AsideCard from "~/modules/product/ui/components/AsideCard.vue";

const props = defineProps({
  product: { type: Product, required: true },
  navigationHeight: { type: Number, required: false },
});

const patternSeo = {
  metaTitle: {
    postfix: () => _T("@Review and video"),
  },
  metaDescription: {
    prefix: () => _T(" @Review and video"),
    postfix: () => _T("@online store") + " | GreenVision",
  },
  metaKeywords: {
    postfix: () => _T("@Review and video"),
  },
};

configureProductSeo(props.product, patternSeo);
</script>

<style lang="scss" scoped>
.video {
  @include flex-container(row, center, flex-start);
  gap: 44px;

  @include bigMobile {
    gap: 16px;
  }

  &__w {
    width: 100%;
    max-width: 880px;

    @include flex-container(column, flex-start);
    gap: 16px;
  }

  &__player-list {
    @include flex-container(row, flex-start, center);
    flex-wrap: wrap;
    gap: 16px;
  }

  &__player-w {
    @include flex-container(column, space-between);
    gap: 16px;
  }

  &__player {
    width: 100%;
    max-width: 880px;

    border-radius: 16px;
    border: none;

    aspect-ratio: 16 / 9;
  }
}
</style>
